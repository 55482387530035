import { isFunction, coerceArray, filterNil, isUndefined } from '@ngneat/elf';
import { BehaviorSubject, map, startWith, pairwise, filter as filter$1 } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { selectEntities, upsertEntities, selectEntity, getEntitiesIds } from '@ngneat/elf-entities';
class StateHistory {
  constructor(store, options = {}) {
    this.store = store;
    this.options = options;
    this.paused = false;
    this.history = {
      past: [],
      present: null,
      future: []
    };
    this.skipUpdate = false;
    this.hasPastSubject = new BehaviorSubject(false);
    this.hasFutureSubject = new BehaviorSubject(false);
    this.hasPast$ = this.hasPastSubject.asObservable().pipe(distinctUntilChanged());
    this.hasFuture$ = this.hasFutureSubject.asObservable().pipe(distinctUntilChanged());
    this.mergedOptions = {
      maxAge: 10,
      comparatorFn: () => true,
      resetFutureOnNewState: false,
      ...options
    };
    this.activate();
  }
  get hasPast() {
    return this.history.past.length > 0;
  }
  get hasFuture() {
    return this.history.future.length > 0;
  }
  getPast() {
    return this.history.past;
  }
  getFuture() {
    return this.history.future;
  }
  activate() {
    this.subscription = this.store.pipe(filter(() => !(this.skipUpdate || this.paused))).subscribe(present => {
      const past = this.history.present;
      const shouldUpdate = !past || this.mergedOptions.comparatorFn(past, present);
      if (shouldUpdate) {
        if (this.history.past.length === this.mergedOptions.maxAge) {
          this.history.past = this.history.past.slice(1);
        }
        if (past) {
          this.history.past = [...this.history.past, past];
        }
        this.history.present = present;
        if (this.mergedOptions.resetFutureOnNewState && this.history.future.length > 0) {
          this.history.future = [];
        }
        this.updateHasHistory();
      }
    });
  }
  undo() {
    if (this.history.past.length) {
      const {
        past,
        present,
        future
      } = this.history;
      const previous = past[past.length - 1];
      this.history.past = past.slice(0, past.length - 1);
      this.history.present = previous;
      this.history.future = [present, ...future];
      this.update();
    }
  }
  redo() {
    if (this.history.future.length) {
      const {
        past,
        present,
        future
      } = this.history;
      const next = future[0];
      const newFuture = future.slice(1);
      this.history.past = [...past, present];
      this.history.present = next;
      this.history.future = newFuture;
      this.update();
    }
  }
  jumpToPast(index) {
    if (index < 0 || index >= this.history.past.length) return;
    const {
      past,
      future,
      present
    } = this.history;
    const newPast = past.slice(0, index);
    const newFuture = [...past.slice(index + 1), present, ...future];
    const newPresent = past[index];
    this.history.past = newPast;
    this.history.present = newPresent;
    this.history.future = newFuture;
    this.update();
  }
  jumpToFuture(index) {
    if (index < 0 || index >= this.history.future.length) return;
    const {
      past,
      future,
      present
    } = this.history;
    const newPast = [...past, present, ...future.slice(0, index)];
    const newPresent = future[index];
    const newFuture = future.slice(index + 1);
    this.history.past = newPast;
    this.history.present = newPresent;
    this.history.future = newFuture;
    this.update();
  }
  /**
   *
   * jump n steps in the past or forward
   *
   */
  jump(n) {
    if (n > 0) return this.jumpToFuture(n - 1);
    if (n < 0) return this.jumpToPast(this.history.past.length + n);
  }
  /**
   *
   * Clear the history
   *
   * @param customUpdateFn Callback function for only clearing part of the history
   *
   * @example
   *
   * stateHistory.clear((history) => {
   *  return {
   *    past: history.past,
   *    present: history.present,
   *    future: []
   *  };
   * });
   */
  clear(customUpdateFn) {
    this.history = isFunction(customUpdateFn) ? customUpdateFn(this.history) : {
      past: [],
      present: null,
      future: []
    };
    this.updateHasHistory();
  }
  destroy({
    clearHistory = false
  } = {}) {
    if (clearHistory) {
      this.clear();
    }
    this.subscription?.unsubscribe();
  }
  pause() {
    this.paused = true;
  }
  resume() {
    this.paused = false;
  }
  update() {
    this.skipUpdate = true;
    this.store.update(() => this.history.present);
    this.updateHasHistory();
    this.skipUpdate = false;
  }
  updateHasHistory() {
    this.hasFutureSubject.next(this.hasFuture);
    this.hasPastSubject.next(this.hasPast);
  }
}
function stateHistory(store, options = {}) {
  return new StateHistory(store, options);
}
class EntitiesStateHistory {
  constructor(store, options = {}) {
    this.store = store;
    this.options = options;
    this.entitiesHistory = new Map();
    this.entitiesChanges = new Map();
    this.pausedEntitiesChanges = new Set();
    this.skipUpdate = false;
    this.mergedOptions = {
      maxAge: 10,
      comparatorFn: () => true,
      ...options
    };
    this.activate();
  }
  activate() {
    /**
     * If we want to check specific entities.
     */
    if (this.mergedOptions.entityIds) {
      coerceArray(this.mergedOptions.entityIds).forEach(id => {
        this.subscribeToEntityChanges(id);
      });
    } else {
      const getIds = () => this.store.query(getEntitiesIds(this.entitiesRef)) ?? [];
      this.subscription = this.store.pipe(selectEntities(this.entitiesRef), map(getIds), startWith(getIds()), pairwise()).subscribe(([prevEntities, currentEntities]) => {
        if (!prevEntities.length && !currentEntities.length) {
          return;
        }
        const currentIdsMap = new Set(currentEntities);
        const allChangedIdsMap = new Set([...prevEntities, ...currentEntities]);
        allChangedIdsMap.forEach(entityId => {
          if (!currentIdsMap.has(entityId)) {
            this.unsubscribeFromEntityChanges(entityId);
          } else if (!this.entitiesChanges.has(entityId)) {
            this.subscribeToEntityChanges(entityId);
          }
        });
      });
    }
  }
  hasPast(id) {
    const historyById = this.entitiesHistory.get(id);
    return historyById ? historyById.past.length > 0 : false;
  }
  hasFuture(id) {
    const historyById = this.entitiesHistory.get(id);
    return historyById ? historyById.future.length > 0 : false;
  }
  getEntitiesPast(options = {
    showIfEmpty: false
  }) {
    const pastByIds = {};
    this.entitiesHistory.forEach((history, id) => {
      if (options.showIfEmpty || history.past.length) {
        pastByIds[id] = history.past;
      }
    });
    return pastByIds;
  }
  getEntitiesFuture(options = {
    showIfEmpty: false
  }) {
    const futureByIds = {};
    this.entitiesHistory.forEach((history, id) => {
      if (options.showIfEmpty || history.future.length) {
        futureByIds[id] = history.future;
      }
    });
    return futureByIds;
  }
  undo(ids) {
    const entities = [];
    this.getEntitiesIds(ids).forEach(id => {
      const history = this.entitiesHistory.get(id);
      if (history?.past.length) {
        const {
          past,
          present,
          future
        } = history;
        const newPresent = past[past.length - 1];
        this.entitiesHistory.set(id, {
          past: past.slice(0, past.length - 1),
          present: newPresent,
          future: [present, ...future]
        });
        entities.push(newPresent);
      }
    });
    this.update(entities);
  }
  redo(ids) {
    const entities = [];
    this.getEntitiesIds(ids).forEach(id => {
      const history = this.entitiesHistory.get(id);
      if (history?.future.length) {
        const {
          past,
          present,
          future
        } = history;
        const newPresent = future[0];
        const newFuture = future.slice(1);
        this.entitiesHistory.set(id, {
          past: [...past, present],
          present: newPresent,
          future: newFuture
        });
        entities.push(newPresent);
      }
    });
    this.update(entities);
  }
  jumpToPast(index, ids) {
    const entities = [];
    this.getEntitiesIds(ids).forEach(id => {
      const history = this.entitiesHistory.get(id);
      if (history?.past.length) {
        if (index < 0 || index >= history.past.length) return;
        const {
          past,
          present,
          future
        } = history;
        const newPast = past.slice(0, index);
        const newFuture = [...past.slice(index + 1), present, ...future];
        const newPresent = past[index];
        this.entitiesHistory.set(id, {
          past: newPast,
          present: newPresent,
          future: newFuture
        });
        entities.push(newPresent);
      }
    });
    this.update(entities);
  }
  jumpToFuture(index, ids) {
    const entities = [];
    this.getEntitiesIds(ids).forEach(id => {
      const history = this.entitiesHistory.get(id);
      if (history?.future.length) {
        if (index < 0 || index >= history.future.length) return;
        const {
          past,
          present,
          future
        } = history;
        const newPast = [...past, present, ...future.slice(0, index)];
        const newPresent = future[index];
        const newFuture = future.slice(index + 1);
        this.entitiesHistory.set(id, {
          past: newPast,
          present: newPresent,
          future: newFuture
        });
        entities.push(newPresent);
      }
    });
    this.update(entities);
  }
  /**
   *
   * Clear the past history.
   * It's different from clear method since it doesn't remove present state
   *
   * @param ids Entities ids which past history will be erased
   *
   * @example
   *
   * stateHistory.clearPast([1, 2]);
   */
  clearPast(ids) {
    this.getEntitiesIds(ids).forEach(id => {
      const entityHistory = this.entitiesHistory.get(id);
      if (entityHistory) {
        this.entitiesHistory.set(id, {
          ...entityHistory,
          past: []
        });
      }
    });
  }
  /**
   *
   * Clear the future history
   * It's different from clear method since it doesn't remove present state
   *
   * @param ids Entities ids which future history will be erased
   *
   * @example
   *
   * stateHistory.clearFuture([1, 2]);
   */
  clearFuture(ids) {
    this.getEntitiesIds(ids).forEach(id => {
      const entityHistory = this.entitiesHistory.get(id);
      if (entityHistory) {
        this.entitiesHistory.set(id, {
          ...entityHistory,
          future: []
        });
      }
    });
  }
  /**
   *
   * Clear the history
   *
   * @param ids Entities ids which history will be erased
   *
   * @param customUpdateFn Update function to update history manually
   *
   * @example
   *
   * stateHistory.clear([1, 2]);
   */
  clear(ids, customUpdateFn) {
    this.getEntitiesIds(ids).forEach(id => {
      const entityHistory = this.entitiesHistory.get(id);
      if (entityHistory) {
        const newHistory = customUpdateFn && customUpdateFn(entityHistory);
        if (newHistory) {
          this.entitiesHistory.set(id, newHistory);
        } else {
          this.entitiesHistory.delete(id);
        }
      }
    });
  }
  destroy({
    clearHistory = false
  } = {}) {
    if (clearHistory) {
      this.clear();
    }
    this.subscription?.unsubscribe();
    this.entitiesChanges.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.entitiesChanges.clear();
  }
  pause(ids) {
    this.getEntitiesIds(ids).forEach(id => {
      this.pausedEntitiesChanges.add(id);
    });
  }
  resume(ids) {
    this.getEntitiesIds(ids).forEach(id => {
      this.pausedEntitiesChanges.delete(id);
    });
  }
  get entitiesRef() {
    return this.mergedOptions.entitiesRef ? {
      ref: this.mergedOptions.entitiesRef
    } : {};
  }
  update(entities) {
    if (!entities.length) {
      return;
    }
    this.skipUpdate = true;
    this.store.update(upsertEntities(entities, this.entitiesRef));
    this.skipUpdate = false;
  }
  subscribeToEntityChanges(id) {
    const subscription = this.store.pipe(selectEntity(id, this.entitiesRef), filterNil(), filter$1(() => !(this.skipUpdate || this.pausedEntitiesChanges.has(id)))).subscribe(entity => {
      const entityHistory = this.entitiesHistory.get(id) || this.getDefaultHistory();
      const prevEntity = entityHistory.present;
      const shouldUpdate = !prevEntity || this.mergedOptions.comparatorFn(prevEntity, entity);
      if (shouldUpdate) {
        if (entityHistory.past.length === this.mergedOptions.maxAge) {
          entityHistory.past = entityHistory.past.slice(1);
        }
        this.entitiesHistory.set(id, {
          ...entityHistory,
          present: entity,
          past: prevEntity ? [...entityHistory.past, prevEntity] : [...entityHistory.past]
        });
      }
    });
    this.entitiesChanges.set(id, subscription);
  }
  unsubscribeFromEntityChanges(id) {
    this.entitiesChanges.get(id)?.unsubscribe();
    this.entitiesChanges.delete(id);
    this.entitiesHistory.delete(id);
  }
  getEntitiesIds(ids) {
    if (isUndefined(ids)) {
      if (this.mergedOptions.entityIds) {
        return coerceArray(this.mergedOptions.entityIds);
      }
      return this.store.query(getEntitiesIds(this.entitiesRef));
    }
    return coerceArray(ids);
  }
  getDefaultHistory() {
    return {
      past: [],
      present: null,
      future: []
    };
  }
}
function entitiesStateHistory(store, options = {}) {
  return new EntitiesStateHistory(store, options);
}
export { entitiesStateHistory, stateHistory };