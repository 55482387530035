import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig, BookingConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { BookingStatusDialogComponent } from 'shared/components/dialog/booking-status-dialog/booking-status-dialog.component';
import { merge } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class FuseConfirmationService {
    private _matDialog: MatDialog = inject(MatDialog);
    private _defaultConfig: FuseConfirmationConfig = {
        title: 'Confirm action',
        message: 'Are you sure you want to confirm this action?',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
        },
        actions: {
            confirm: {
                show: true,
                label: 'Confirm',
                color: 'warn',
            },
            cancel: {
                show: true,
                label: 'Cancel',
            },
        },
        dismissible: false,
    };

    private _defaultBookingConfig: BookingConfirmationConfig = {
        title: 'Confirm action',
        message: 'Are you sure you want to confirm this action?',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
        },
        actions: {
            confirm: {
                show: true,
                label: 'Confirm',
                color: '#E03E3E',
                backgroundColor: '#FEE2E2',
            },
            cancel: {
                show: true,
                label: 'Cancel',
            },
        },
        dismissible: false,
        fileRequired: false,
        comments: '',
        fileUrl: '',
        iconImage: {
            show: true,
            iconUrl: 'images/icons/warning.png',
            iconBackgroundColor: '#FEE2E2',
        },
        allocateDialog: false,
        type: 'confirm',
        allocatedTo: '',
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(
        config: FuseConfirmationConfig = {}
    ): MatDialogRef<FuseConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel',
        });
    }

    openCustomDialog(
        config: BookingConfirmationConfig = {}
    ): MatDialogRef<BookingStatusDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultBookingConfig, config);

        // Open the dialog
        return this._matDialog.open(BookingStatusDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel',
        });
    }
}
