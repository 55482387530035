import { NgClass, CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BookingConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserModel } from 'shared/store/user/user.model';
import { tap, map } from 'rxjs';
import { UserStoreService } from 'shared/store/user/user.store';
import { MatSelectModule } from '@angular/material/select';
import { slotList } from 'shared/data/data';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-booking-status-dialog',
  standalone: true,
  templateUrl: './booking-status-dialog.component.html',
  styleUrl: './booking-status-dialog.component.scss',
  styles: [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-mdc-dialog-container {
                    .mat-mdc-dialog-surface {
                        padding: 0 !important;
                    }
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, NgClass, ReactiveFormsModule, CommonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDatepickerModule],
    providers: [UserStoreService]
})
export class BookingStatusDialogComponent implements OnInit {
    data: BookingConfirmationConfig = inject(MAT_DIALOG_DATA);
    bookingStatusForm: FormGroup;

    fileUrl: string = "";
    uploadedFileInfo: string;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<BookingStatusDialogComponent>,
        private _userStoreService: UserStoreService
    ) {}

    userList$;

    ngOnInit(): void {
        this.bookingStatusForm = this.fb.group({
            comments: [''],
            file: [null],
            allocateTo: [''],
            bookingDate: [''],
            timeSlot: ['']
        });

        if (this.data.type === 'file-upload') {
            this.bookingStatusForm.get('file').setValidators(Validators.required);
        }

        this.bookingStatusForm.get('comments').setValue(this.data.comments);
        if(this.data.fileUrl) {
            this.fileUrl = this.data.fileUrl;
        }

        if (this.data.type === 'allocate'){
            this.bookingStatusForm.get('allocateTo').setValidators(Validators.required);

            this.userList$ = this._userStoreService.selectAll().pipe(
                map((users: UserModel[]) => users.filter(user => user.roleId == "66fbb55b40efed7cfaf3b39c")),
                tap()
            )

            this.userList$
                .pipe()
                .subscribe((allUsers) => {
                    this.userList = allUsers;
                    this.filteredUsersList = allUsers;
            });
            this.bookingStatusForm.get('allocateTo').setValue(this.data.allocatedTo);
        }

        if(this.data.type === 'reschedule'){
            this.bookingStatusForm.get('bookingDate').setValidators(Validators.required);
            this.bookingStatusForm.get('timeSlot').setValidators(Validators.required);

            let [date, slot] = this.convertTimeToSlot(
                this.data.bookingSchedule.start
            );
            this.bookingStatusForm.get('bookingDate').setValue(date);
            this.bookingStatusForm.get('timeSlot').setValue(slot);
        }
    }

    onSubmit(): void {
        if (this.bookingStatusForm.valid) {
            const formData = new FormData();
            formData.append('comment', this.bookingStatusForm.get('comment').value);
            formData.append('file', this.bookingStatusForm.get('file').value);

            // Handle form submission
            console.log('Form submitted', formData);
        }
    }

    onFileSelect(event: any): void {
        const file = event.target.files[0];
        this.bookingStatusForm.get('reportfileFile').setValue(file);

        this.uploadedFileInfo = `${file.name} (${this.formatBytes(file.size)})`;
    }

    formatBytes(bytes: number): string {
        const UNITS = [
            'Bytes',
            'kB',
            'MB',
            'GB',
            'TB',
            'PB',
            'EB',
            'ZB',
            'YB',
        ];
        const factor = 1024;
        let index = 0;

        while (bytes >= factor) {
            bytes /= factor;
            index++;
        }

        return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    openFile(): void {
        window.open(this.fileUrl + '#toolbar=0', '_blank')  // #toolbar=0 is used to hide the toolbar of pdf viewer
    }

    onSaveClick(): void {
        const [startTIme, endTime] = this.ConvertDateAndSlotToMs();
        const result = {
            comments: this.bookingStatusForm.value.comments,
            file: this.bookingStatusForm.value.file,
            allocateTo: this.bookingStatusForm.value.allocateTo,
            schedule: {
                start: startTIme,
                end: endTime
            }
        };
        this.dialogRef.close(result);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    async openFilePicker(): Promise<void> {
        try {
          const [fileHandle] = await (window as any).showOpenFilePicker({
            types: [
              {
                description: 'PDF Files',
                accept: {
                  'application/pdf': ['.pdf'],
                },
              },
            ],
            excludeAcceptAllOption: true, // This hides the "All files" option
            multiple: false,
          });

          const file = await fileHandle.getFile();
          this.bookingStatusForm.get('file').setValue(file);
          this.uploadedFileInfo = `${file.name} (${this.formatBytes(file.size)})`;
        } catch (error) {
          console.error('Error selecting file:', error);
        }
    }

    userList: UserModel[];

    filteredUsersList: UserModel[];

    public filterUsers(event: any): void {
        let filter = event.target.value;

        this.filteredUsersList = this.userList.filter((x) =>
            (x.firstName + ' ' + x.lastName).toLowerCase().includes(filter.toLowerCase())
        );
    }

    slotList = slotList;

    getFilteredSlotList() {

        if (new Date(this.bookingStatusForm.value.bookingDate).toLocaleDateString() == new Date().toLocaleDateString()) {
            const currentHour = new Date().getHours();
            let exist = false;
            const filteredSlotList = this.slotList.filter(slot => {
                if (slot.value > (currentHour + 1) && slot.value === this.bookingStatusForm.value.timeSlot) {
                    exist = true;
                }
                return slot.value > (currentHour + 1);
            });


            if(!exist) {
                this.bookingStatusForm.get('timeSlot').setValue('');
            }
            return filteredSlotList;
        } else {
            return slotList;
        }
    }

    ConvertDateAndSlotToMs() {
        const date = this.bookingStatusForm.get('bookingDate').value;
        const slotValue = this.bookingStatusForm.get('timeSlot').value;

        const startTime = new Date(date).getTime() + slotValue * 60 * 60 * 1000;
        const endTime = startTime + 60 * 60 * 1000;

        return [startTime, endTime];
    }

    convertTimeToSlot(time: number) {
        const now = new Date(time);

        const startOfDay = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            0,
            0,
            0,
            0
        );


        const startOfDayInMs = startOfDay.getTime();

        // let todayStartTime = new Date(date).getTime();

        let slot = (time - startOfDayInMs) / (60 * 60 * 1000);

        return [new Date(startOfDayInMs), slot];
    }

    getMinDate(): Date {
        const now = new Date();
        const currentHour = now.getHours();

        if (currentHour >= slotList[slotList.length - 1].value - 1) {
            // If difference between current time and last slot start is less than 1hr, return tomorrow's date
            const tomorrow = new Date();
            tomorrow.setDate(now.getDate() + 1);
            return tomorrow;
        } else {
            // Otherwise, return today's date
            return now;
        }
    }
}
