import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';
// import { jwtDecode } from "jwt-decode";

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const _jwtHelper = inject(JwtHelperService);

    const token = localStorage.getItem('accessToken');
    if(token){
        const decodedToken: any = _jwtHelper.decodeToken(token)
        if(decodedToken.exp < Date.now() / 1000){
            localStorage.removeItem('accessToken');
            router.navigate(['/sign-in']);
            return true;
        }
        const userRole = decodedToken.roleId

        const expectedRole = route.data['expectedRole'];
        if (userRole && expectedRole.includes(userRole)){
            return true;
        }
        else{
            if(userRole == '66fbb54940efed7cfaf3b39a'){ //Front desk Role
                router.navigate(['/booking/list']);
            }
            else if(userRole == '66fbb55b40efed7cfaf3b39c' || userRole == '66fbb52e40efed7cfaf3b398'){ // Collection Partner Role or Customer Role
                localStorage.removeItem('accessToken');
                router.navigate(['/sign-in']);
            }
            return false;
        }
    }
    else{
        router.navigate(['/sign-in']);
        return true;
    }
    // Check the authentication status
    // return inject(AuthService)
    //     .check()
    //     .pipe(
    //         switchMap((authenticated) => {
    //             // If the user is not authenticated...
    //             if (!authenticated) {
    //                 // Redirect to the sign-in page with a redirectUrl param
    //                 const redirectURL =
    //                     state.url === '/sign-out'
    //                         ? ''
    //                         : `redirectURL=${state.url}`;
    //                 const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

    //                 return of(urlTree);
    //             }

    //             // Allow the access
    //             return of(true);
    //         })
    //     );
};
